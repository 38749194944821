<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-card elevation="0" width="100%" max-width="1100" class="transparent mb-5">
        <v-toolbar flat class="transparent mb-4">
          <h1 class="headline black--text">{{ $t('menu.beneficiaries') }}</h1>
          <v-spacer />
          <v-btn rounded color="primary" depressed to="/settings/beneficiary">{{ $t('common.create') }}</v-btn>
        </v-toolbar>
        <v-card>
          <v-sheet v-if="isLoading" height="300">
            <v-layout fill-height align-center justify-center>
              <v-progress-circular width="2" indeterminate color="primary" />
            </v-layout>
          </v-sheet>
          <v-simple-table v-if="!isLoading">
            <template v-slot:default>
              <thead class="grey lighten-4">
                <tr>
                  <th>{{ $t('common.name') }}</th>
                  <th>{{ $t('beneficiaries.relationship') }}</th>
                  <th>{{ $t('patient.birthdate') }}</th>
                  <th width="1%">{{ $t('common.actions') }}</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="!isLoading">
                  <tr v-for="item in beneficiaries" :key="item.id">
                    <td>{{ item.name + ' ' + item.surname }}</td>
                    <td>{{ item.relationship }}</td>
                    <td>{{ item.birthdate | moment('DD/MM/YYYY') }}</td>
                    <td>
                      <v-btn icon :to="'/settings/beneficiary/' + item.id">
                        <v-icon>mdi-account-edit-outline</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                  <tr v-if="!beneficiaries || !beneficiaries.length">
                    <td colspan="4" class="pa-5 text-center">{{ $t('beneficiaries.no_beneficiaries') }}</td>
                  </tr>
                </template>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
        <template v-if="totalResults && !isLoading">
          <div class="my-5 text-center">
            <!--            <div class="my-3 grey&#45;&#45;text body-2">-->
            <!--              Results {{ currentPage * perPage - perPage + 1 }} - -->
            <!--              {{ Math.min(currentPage * perPage, totalResults) }} of-->
            <!--              {{ totalResults }}-->
            <!--            </div>-->
            <v-pagination
              v-if="totalPages > 1"
              v-model="currentPage"
              :length="totalPages"
              text
              :total-visible="6"
              circle
              color="primary"
              @input="changePage"
            />
          </div>
        </template>
      </v-card>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions } from 'vuex';
import { beneficiariesUrl, getHeader, urlPatient, displayNameEnv } from '../../config/config';

export default {
  title: displayNameEnv + ' - ' + vm.$t('menu.beneficiaries'),
  data() {
    return {
      isLoading: false,
      currentPage: 1,
      perPage: 10,
      totalPages: null,
      totalResults: null,
      beneficiaries: null,
    };
  },
  mounted() {
    const msg = JSON.parse(window.localStorage.getItem('msg_ehealth'));
    if (msg && msg.message !== '') {
      this.showAlert(msg);
      localStorage.removeItem('msg_ehealth');
    }
    this.loadBeneficiaries();
  },
  methods: {
    ...mapActions('layout', ['showAlert']),

    changePage(page) {
      this.currentPage = page;
      this.loadBeneficiaries();
    },

    loadBeneficiaries() {
      this.$scrollTo('#appRoot');
      this.isLoading = true;

      this.$http
        .get(urlPatient(beneficiariesUrl, this.perPage, this.currentPage), { headers: getHeader() })
        .then(res => {
          this.beneficiaries = res.body.data;
          this.totalResults = res.body._meta.total;
          this.totalPages = Math.ceil(this.totalResults / this.perPage);
        })
        .catch(err => {
          this.$log.error(err);
          let msg = 'Ocurrió un error!';
          if (err && err.response && err.response.data) {
            msg = err.response.data.detail;
          }
          this.toastError(msg);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
